import React,{useEffect, useRef} from 'react'
import './header.css'
import Info from './Info'
import HeaderSocial from './HeaderSocial'
import Img from '../../assets/img.png'
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Header = () => {
    const NomRef = useRef()
  
    const Chargement = () => {
        gsap
          .timeline()
          .fromTo(
            ".lettre",
            {
              x: -100,
              opacity: 0,
            },
            {
              x: 0,
              opacity: 1,
              stagger: 0.33,
              delay: 0.7,
            }
          )
          .to(".lettre", {
            margin: "0 0.3vw",
            delay: 0.8,
            duration: 0.5,
            color: "#3891cc",
          })
          .to(".lettre", {
            margin: "0",
            delay: 0.8,
            duration: 0.5,
            color: "",
          });
    }
    
    useEffect(() => {
        Chargement()
    }, []);

  return (
    <>
      <header id="header">
        <div className="container header__container">
          <h5>Bonjour, je suis</h5>
          <h1 className="nom" ref={NomRef}>
            <span className="lettre">T</span>
            <span className="lettre">R</span>
            <span className="lettre">A</span>
            <span className="lettre">O</span>
            <span className="lettre">R</span>
            <span className="lettre">E </span>
            <span className="lettre">L</span>
            <span className="lettre">a</span>
            <span className="lettre">m</span>
            <span className="lettre">i</span>
            <span className="lettre">n</span>
            <span className="lettre">e</span>
          </h1>
          <h5 className="text-light">Développeur FullStack</h5>
          <Info />
          <div className="img-me">
            <img src={Img} alt="monImage" className="img__deux" />
          </div>

          <a href="#contact" className="scoll_down">
            Allez en bas
          </a>
          <HeaderSocial />
        </div>
      </header>
    </>
  );
}

export default Header