import React from 'react'
import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

const Footer = () => {
  return (
    <footer id="footer">
      <span href="#header" className="footer__logo">
        NovzChron
      </span>

      <ul className="footer__links">
        <li>
          <a href="#header">Acceuil</a>
        </li>
        <li>
          <a href="#propos">A Propos</a>
        </li>
        <li>
          <a href="#experience">Expérience</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="https://x.com/traorelamine1" rel="noreferrer">
          <FaXTwitter />
        </a>
        <a href="https://web.facebook.com/lamine.traore.1998/" rel="noreferrer">
          <BsFacebook />{" "}
        </a>
        <a
          href="https://www.linkedin.com/in/traore-lamine-84bb7423a/"
          rel="noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </div>
      <div className="footer__copyright">
        <small>&copy; NovzChron. Tout les droits réservés.</small>
      </div>
    </footer>
  );
}

export default Footer