import React from 'react'
import {BsLinkedin,BsGithub} from 'react-icons/bs'
import { FaXTwitter } from 'react-icons/fa6';

const HeaderSocial = () => {
  return (
    <div className="header_socials">
      <a
        href="https://www.linkedin.com/in/traore-lamine-84bb7423a/"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a href="https://github.com/NovzChrono" rel="noreferrer">
        <BsGithub />{" "}
      </a>
      <a href="https://x.com/traorelamine1" rel="noreferrer">
        <FaXTwitter />{" "}
      </a>
    </div>
  );
}

export default HeaderSocial